import React, {Component, useEffect} from "react";
import {useForm} from "react-hook-form";
import { Html } from '@react-email/html';
import { render } from '@react-email/render';
import sendgrid from '@sendgrid/mail';
//import Select from 'react-select'
import {useLocation} from '@reach/router';
import {Link, navigate} from "gatsby"
//import {swipeTransition, clubs} from "../constants"
//import * as styles from '../styles/index.module.scss'

import Layout from '../components/layout'
import {GlobalDispatchContext, GlobalStateContext} from '../context/context'

const RegisterPage = () => {

  const dispatch = React.useContext(GlobalDispatchContext);
  const state = React.useContext(GlobalStateContext);

  const {register, handleSubmit, watch, formState: {
    errors
  }} = useForm();


  useEffect(() => {
    dispatch({type: "UPDATE_LOGIN_ERROR", payload: null})

  }, [])

  
const login = (username,password) => {

        // dispatch({type: "UPDATE_USERNAME", payload: data})
        // dispatch({type: "UPDATE_PASSWORD", payload: data})
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ username:username, password:password })
        };    
        
          
        fetch("https://lead5050.staging.parachute.net/wp-json/jwt-auth/v1/token",requestOptions)
        .then(response => response.json())
        .then(data => {
          if (data.token != null){
            dispatch({type: "LOGGED_IN", payload: true})

            dispatch({type: "UPDATE_TOKEN", payload: data})
            navigate("/");
    
          }else{
            dispatch({type: "UPDATE_LOGIN_ERROR", payload: data.message})
    
          }
        }).catch((error) => {
          dispatch({type: "UPDATE_LOGIN_ERROR", payload: "Failed to login - please check your details"})
    
        })
 
}

  const onSubmit = (data) => {
    
    fetch(`https://lead5050.staging.parachute.net/?rest_route=/simple-jwt-login/v1/users`, {
        method: 'POST',
        body: JSON.stringify({ email: data.username, password: data.password, first_name: data.firstname, last_name:data.surname}),

    }).then((response) => response.json()).then((responseJson) => {
        if (responseJson.success == true){
  
            login(data.username,data.password);


            // sendgrid.setApiKey("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");

            // const emailHtml = render(<>
            // <h2>WELL DONE MY SON/DAUGHTER! A new submission on the portal.</h2>
            // <p><strong>Username/email</strong>{data.username}</p>
            // <p><strong>First Name</strong>{data.firstname}</p>
            // <p><strong>Surname</strong>{data.surname}</p>
            // <p><strong>Company</strong>{data.company}</p>
            // <p><strong>Position</strong>{data.position}</p>
            // </>);

            // const options = {
            //   from: 'development@parachute.net',
            //   to: ['lead5050@gmail.com','david@parachute.net'],
            //   subject: 'New Submission',
            //   html: emailHtml,
            // };

            // sendgrid.send(options);


            fetch(`https://sheet.best/api/sheets/baba51fa-7d0d-458e-99d3-85f636954d9f`, {
              method: 'POST',
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ emailaddress: data.username, position: data.position, firstname: data.firstname, surname:data.surname, company: data.company, datetime: new Date().toISOString() }),
      
          }).then((response) => response.json()).then((responseJson) => {
            console.log(responseJson);

          });

        }else{  
            dispatch({type: "UPDATE_LOGIN_ERROR", payload: "Failed to create this username"})
        }

      

    }).catch((error) => {

       

    });
  };

const renderLoginError =() => {
  if (state != null) {

  if (state.loginError != null && state.loginError != ""){
    return(
    <div className="error-box">
      {state.loginError}
    </div>
  )
    }
}
}
if (state != null) {

  return (

    <Layout pageTitle="Home Page">

    <div className="container">
        <div className="row">
            <div className="col-md-12">                
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-md-9">
                            <div className="user-content text-center block-padding-medium">
                                <h1>Register</h1>
                                <p>Search organisations and find out what’s causing their score, what’s changed since last year, and what they’re saying about their Lead5050 Equity Index (where the employer has provided a statement - it’s voluntary).  </p>
                            </div>
                        </div>
                    </div>
                </div>        
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="gform-body">
                <div className="gform-fields">
           
         
            <div className="common-background row">
    
    {renderLoginError()}
    <div className="gfield col-md-6">
    <label>First Name</label>

            <input
                
                  type="text"
                  placeholder="First Name"
                  {...register("firstname", { required: true })}/> {errors.username && <span >this field is required</span>}
         </div>
         <div className="gfield col-md-6">
         <label>Surname</label>

         <input
                
                  type="text"
                  placeholder="Surname"
                  {...register("surname", { required: true })}/> {errors.password && <span >this field is required</span>}
    </div>
    
    <div className="gfield col-md-6">
    <label>Company</label>

            <input
                
                  type="text"
                  placeholder="Company"
                  {...register("company", { required: true })}/> {errors.username && <span >this field is required</span>}
         </div>
         <div className="gfield col-md-6">
    <label>Position</label>
         <input
                
                  defaultValue={state.password}
                  type="text"
                  placeholder="Position"
                  {...register("position", { required: true })}/> {errors.password && <span >this field is required</span>}
    </div>


    <div className="gfield col-md-6">
    <label>Email Address</label>

            <input
                
                  type="email"
                  placeholder="Email Address"
                  {...register("username", { required: true })}/> {errors.username && <span >this field is required</span>}
         </div>
         <div className="gfield col-md-6">
         <label>Password</label>

         <input
                
                  defaultValue={state.password}
                  type="password"
                  placeholder="Password"
                  {...register("password", { required: true })}/> {errors.password && <span >this field is required</span>}
    </div>
    <div className="gfield col-md12">
    <label  style={{width:"auto",margin:"0 0 20px  20px",padding:0}}>By signing up to this service you agree to Lead5050 sending you marketing information.
</label>

         <input style={{width:"auto",margin:0,padding:0,height:"auto",lineHeight:"initial",float:"left"}}
                
                  defaultValue={"checked"}
               
                  type="checkbox"
                  {...register("terms", { required: false })}/> {errors.terms && <div style={{background:"red",color: "white", padding: "10px"}}>this field is required</div>}


    </div>


          </div>
          </div>
          </div>
          <div className="col-md-12 text-center">
          <button>Submit</button>
          </div>
            </form>
    
            </div>
        </div>
    </div> 
    

        </Layout>
  ) 
}
}
export default RegisterPage